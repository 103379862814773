import DefaultView from '@/views/DefaultView';
import { beforeEnter } from './guards';
import { subscription } from '../tools/helpers';

const CoreHeader = () => import('@/components/core/CoreHeader.vue');
const AccountFormsView = () => import('@/modules/login-extended/views/AccountFormsView.vue');

const SubscriptionChoose = () => import('../views/SubscriptionChoose.vue');
const FreeConfirmation = () => import('../components/FreeConfirmation.vue');
const StripeForm = () => import('../components/StripeForm.vue');

export default [
  {
    path: '/subscription',
    name: 'stripe',

    meta: { requiresAuth: true, onlyNewbies: true },

    components: {
      header: CoreHeader,
      default: DefaultView,
    },

    props: {
      header: {
        justLogout: true,
      },
    },

    beforeEnter,

    children: [
      {
        path: 'choose',
        name: subscription.choose,
        component: SubscriptionChoose,
      },

      {
        path: 'thankyou',
        component: AccountFormsView,
        props: {
          showSubtitle: false,
        },
        children: [
          {
            path: 'confirm',
            name: subscription.free,
            component: FreeConfirmation,
          },
        ],
      },

      {
        path: 'payment',
        component: AccountFormsView,
        props: {
          showSubtitle: false,
        },
        children: [
          {
            path: ':plan?',
            name: subscription.paid,
            component: StripeForm,
            props: (route) => {
              let { plan } = route.params;
              return {
                plan,
                enableRecaptcha: false,
              };
            },
          },
        ],
      },
    ],
  },
];
