import { forceSubscriptionRedirect } from '../tools/helpers';

export default {
  namespaced: true,
  state() {
    return {};
  },
  getters: {},
  mutations: {},
  actions: {
    initialize({ dispatch }) {
      console.info('Stripe initializing...');
      console.info('Stripe initialized.');
    },

    redirects({ dispatch, rootGetters, rootState }, { to, from, next }) {
      const { isCustom, hasSubscribed } = rootGetters;
      const { profile } = rootState.user;
      const { targetSubscription } = profile.Parameters ?? {};

      if (!isCustom && !hasSubscribed) {
        forceSubscriptionRedirect(to, from, next, targetSubscription);
        dispatch('app/loaded', null, { root: true });
      }
    },
  },
};
