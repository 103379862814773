import Store from '@/store';

const beforeEnter = async (to, from, next) => {
  const authenticated = await Store.dispatch('CHECK_AUTHENTICATION', { source: 'stripeGuard' });

  const { isProfileLoaded, isCustom, hasSubscribed } = Store.getters;


  if (isCustom || hasSubscribed) {
    next({ path: process.env.VUE_APP_DEFAULT_REDIRECT });
    Store.dispatch('app/loaded', null, { root: true });
  } else {
    next();
  }
};

export { beforeEnter };
